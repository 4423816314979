import addApplicantAction from "../redux/Actions/addApplicantAction";
import isLoadingAction from "../redux/Actions/isLoadingAction";
import filterAction from "../redux/Actions/filterAction";
import { useDispatch, useSelector } from "react-redux";
import ProfileComponent from "./ProfileComponent";
import infinity from "../Assets/infinity.svg.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import axios from "axios";

const ModelButton = () => {
  const [user, setUser] = useState([]);
  const userId = useSelector((state) => state.filterReducer);
  const [activeId, setActiveId] = useState(userId);

  const handleActive = (id) => {
    setActiveId(id);
  };
  const dispatch = useDispatch();

  useEffect(() => {
    const GET_USER = "https://zamdainternational-crm-api.vercel.app/api/users";
    const request = async () => {
      dispatch(isLoadingAction(true));
      try {
        const response = await axios.get(GET_USER);
        setUser(response?.data);
      } catch (err) {
        console.error(err);
      } finally {
        dispatch(isLoadingAction(false));
        try {
          const GET_EMP = `https://zamdainternational-crm-api.vercel.app/api/users/${userId}/applicants`;
          const response = await axios.get(GET_EMP);
          dispatch(addApplicantAction((response?.data).reverse()));
        } catch (err) {
          console.error(err);
        }
      }
    };
    request();
  }, []);

  const handleClick = (e) => {
    const userId = e.target.id;
    handleActive(userId);
    dispatch(filterAction(userId));
    const GET_EMP = `https://zamdainternational-crm-api.vercel.app/api/users/${userId}/applicants`;
    const request = async () => {
      try {
        dispatch(isLoadingAction(true));
        const response = await axios.get(GET_EMP);
        dispatch(addApplicantAction((response?.data).reverse()));
      } catch (error) {
        console.error(error);
      } finally {
        dispatch(isLoadingAction(false));
      }
    };
    request();
  };
  return (
    <>
      <div className="py-4 bg-white lg:w-full md:w-full sm:w-full flex justify-between items-center px-14">
        <span className="bg-white">
          <img
            src={infinity}
            alt="infinity"
            className="bg-white h-[70%] md:h-[70%] sm:h-[70%] w-[65%] lg:w-[14%] md:w-[20%] sm:w-[30%] cursor-pointer"
          />
        </span>
        <span className="bg-white">
          <ProfileComponent />
          <ToastContainer theme="light" className="bg-white" />
        </span>
      </div>
      <div className="w-full">
        <div className="border flex flex-wrap items-center justify-start bg-[#F9FBFD] px-4 py-2 sm:px-6 sm:py-3 text-sm font-semibold text-[#376cff] hover:text-[#4a86d4] cursor-pointer">
          {user.map((value, index) => (
            <div
              key={index}
              id={value._id}
              onClick={handleClick}
              className={`flex items-center justify-center w-full px-4 py-2 md:w-auto md:py-2 text-gray-500 border border-blue-100 rounded-md mr-3 mb-2 shadow-gray-100 shadow-lg transition-all duration-150 scale-100 ${activeId === value._id
                  ? "bg-blue-200"
                  : "hover:scale-105 active:scale-100"
                }`}
            >
              {value.name}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ModelButton;
