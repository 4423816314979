import useLocalStorage from "./useLocalStorage"

const useToggle = (key, initialValue) => {
    const [value, setValue] = useLocalStorage(key, initialValue);
    const setToggle = () => {
        setValue(pre => !pre);
    }
    return [value, setToggle];
}

export default useToggle;