const initialState = false;
const isLoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADER":
      return action.value;
    default:
      return state;
  }
};

export default isLoadingReducer;
