import CountUp from "react-countup";

const DashboardCards = ({ title, value, icon }) => {
  return (
    <>
      <div className="lg:w-[22%] sm:w-[45%] md:w-[30%]">
        <div className="bg-white rounded-md shadow-md shadow-gray-100  p-4">
          <div className="text-zinc-400 bg-white font-semibold text-[11px] sm:text-[12px] md:text-[13px] lg:text-[12px] tracking-wider uppercase">
            {title}
          </div>
          <div className="flex bg-white justify-between items-center">
            <h2 className="font-semibold bg-white text-[18px] sm:text-[20px] md:text-[22px] lg:text-[23px]">
              <CountUp start={0} end={value} delay={0} className="bg-white" />
            </h2>
            <span className="font-semibold text-[18px] sm:text-[20px] md:text-[23px] lg:text-[25px] text-zinc-400">
              {icon}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardCards;
