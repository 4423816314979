import ModelButton from "../components/ModelButton";
import TabsSection from "../components/TabsSection";

const Home = () => {
  return (
    <>
      <ModelButton />
      <TabsSection />
    </>
  );
};

export default Home;
