import addApplicantAction from "../../../redux/Actions/addApplicantAction";
import { useDispatch, useSelector } from "react-redux";
import { RxCrossCircled } from "react-icons/rx";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";

import isLoadingAction from "../../../redux/Actions/isLoadingAction";

const ZTTEditForm = ({ isOpen, onClose, value }) => {
  const userId = useSelector((state) => state.filterReducer);
  const isLoading = useSelector((state) => state.isLoadingReducer);
  const [editValues, setEditValues] = useState(value);
  const dispatch = useDispatch();

  const fileInputRef = useRef(null);
  const [files, setFiles] = useState([]);

  const handleClick = async () => {
    let formData = new FormData();
    files.forEach((file) => {
      formData.append("files[]", file.data);
    });
    // Get the folder ID (make sure this is defined in your component state or passed as a prop)
    const folderId = "18P0OtEpigf5ur9wZpnb4bw5pP-H3He-C"; // Replace with the actual folder ID you want to use

    // Append the folder ID to the form data
    formData.append("folderId", folderId);
    try {
      const response = await axios.post(
        "https://zamdainternational-crm-api.vercel.app/api/upload/upload-file-to-google-drive",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" }, // Set content type for multipart form data
        }
      );
      editValues.GoogleDrivelinks = [
        ...editValues.GoogleDrivelinks,
        ...response?.data?.fileIds,
      ];
    } catch (err) {
      const notify = () => toast.error("Upload Failed", {
        theme: "colored"
      });
      const exceed = () => toast.error("Upload Size Exceeded", {
        theme: "colored"
      });
      (err.status === 500) ? exceed() : notify();
      throw Error("Upload Failed");
    } finally {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      setFiles([]);
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    const filesWithPreview = selectedFiles.map((file) => ({
      preview: URL.createObjectURL(file),
      data: file,
    }));
    setFiles(filesWithPreview);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(isLoadingAction(true));
      const EDIT_EMP = `https://zamdainternational-crm-api.vercel.app/api/users/${userId}/applicants/${value._id}`;
      if (!(files.length === 0)) {
        await handleClick();
      }
      await axios.put(
        EDIT_EMP,
        JSON.stringify({
          firstName: editValues.firstName,
          lastName: editValues.lastName,
          phoneNumber: editValues.phoneNumber,
          email: editValues.email,
          address: editValues.address,
          cnic: editValues.cnic,
          desiredCountry: editValues.desiredCountry,
          dateOfBirth: editValues.dateOfBirth,
          passport: editValues.passport,
          occupation: editValues.occupation,
          region: editValues.region,
          levelOfEducation: editValues.levelOfEducation,
          GoogleDrivelinks: editValues.GoogleDrivelinks,
          status: editValues.status,
          type: value.type,
          IELTS_PTE_Score: editValues.IELTS_PTE_Score,
          matric: editValues.matric,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      const GET_EMP = `https://zamdainternational-crm-api.vercel.app/api/users/${userId}/applicants`;
      const response = await axios.get(GET_EMP);
      dispatch(addApplicantAction((response?.data).reverse()));
    } catch (err) {
      if (!err?.response) {
        console.log("No Server Response");
      } else if (err.response?.status === 409) {
        console.log("Username Taken");
      } else {
        console.log("Registration Failed");
      }
    } finally {
      dispatch(isLoadingAction(false));
    }
    onClose();
  };

  // Sync state with the new value when the component opens or the value prop changes
  useEffect(() => {
    if (isOpen) {
      setEditValues(value);
    }
  }, [isOpen, value]);

  return (
    <>
      <div
        className={`fixed top-0 z-50 left-0  w-full h-full flex justify-end bg-[#717D8C] bg-opacity-25 ${isOpen ? "" : "hidden"
          }`}
      >
        <div className="overflow-scroll fixed top-0 right-0 sidebar_animation  bg-[#F9FBFD] p-5 lg:w-[500px] md:w-[500px] sm:w-[500px] h-full rounded">
          <div className="flex justify-between border-b pb-3">
            <h3 className="font-semibold text-lg">Edit Applicant</h3>
            <button
              onClick={onClose}
              className="font-semibold text-gray-400 text-[32px]"
            >
              <RxCrossCircled />
            </button>
          </div>
          <form className="mt-5 text-sm" onSubmit={handleSubmit}>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="firstName">
                  First Name:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  value={editValues.firstName}
                  onChange={(e) =>
                    setEditValues({
                      ...editValues,
                      firstName: e.currentTarget.value,
                    })
                  }
                  placeholder="Enter first name"
                  required
                />
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="lastName">
                  Last Name:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  value={editValues.lastName}
                  onChange={(e) =>
                    setEditValues({
                      ...editValues,
                      lastName: e.currentTarget.value,
                    })
                  }
                  placeholder="Enter last name"
                  required
                />
              </div>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="email">Email Address:</label>
              <input
                type="text"
                name="email"
                id="email"
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                value={editValues.email}
                onChange={(e) =>
                  setEditValues({
                    ...editValues,
                    email: e.currentTarget.value,
                  })
                }
                placeholder="Emai@gmail.com"
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="number">
                Phone Number:
                <span className="text-red-700 text-[15px]"> *</span>
              </label>
              <input
                type="text"
                name="phoneNumber"
                id="phoneNumber"
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                value={editValues.phoneNumber}
                onChange={(e) =>
                  setEditValues({
                    ...editValues,
                    phoneNumber: e.currentTarget.value,
                  })
                }
                placeholder="Enter phone number"
                required
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="address">
                Address:
                <span className="text-red-700 text-[15px]"> *</span>
              </label>

              <input
                type="text"
                name="address"
                id="address"
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                value={editValues.address}
                onChange={(e) =>
                  setEditValues({
                    ...editValues,
                    address: e.currentTarget.value,
                  })
                }
                placeholder="Enter your address"
                required
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="cnic">
                C.N.I.C Number:
                <span className="text-red-700 text-[15px]"> *</span>
              </label>
              <input
                type="text"
                name="cnic"
                id="cnic"
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                value={editValues.cnic}
                onChange={(e) =>
                  setEditValues({
                    ...editValues,
                    cnic: e.currentTarget.value,
                  })
                }
                placeholder="C.N.I.C Number"
                required
              />
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="desiredCountry">
                  Desired Country:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="text"
                  name="desiredCountry"
                  id="desiredCountry"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  value={editValues.desiredCountry}
                  onChange={(e) =>
                    setEditValues({
                      ...editValues,
                      desiredCountry: e.currentTarget.value,
                    })
                  }
                  placeholder="Enter desired country"
                  required
                />
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="IELTS_PTE_Score">
                  IELTS/PTE Score:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="text"
                  name="IELTS_PTE_Score"
                  id="IELTS_PTE_Score"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  value={editValues.IELTS_PTE_Score}
                  onChange={(e) =>
                    setEditValues({
                      ...editValues,
                      IELTS_PTE_Score: e.currentTarget.value,
                    })
                  }
                  placeholder="Enter score"
                  required
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="dateOfBirth">
                  Date Of Birth:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="date"
                  name="dateOfBirth"
                  id="dateOfBirth"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  value={editValues.dateOfBirth}
                  onChange={(e) =>
                    setEditValues({
                      ...editValues,
                      dateOfBirth: e.currentTarget.value,
                    })
                  }
                  placeholder=""
                  required
                />
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="passport">Passport Number</label>
                <input
                  type="text"
                  name="passport"
                  id="passport"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  value={editValues.passport}
                  onChange={(e) =>
                    setEditValues({
                      ...editValues,
                      passport: e.currentTarget.value,
                    })
                  }
                  placeholder="Enter passport number"
                />
              </div>
            </div>

            <div className="grid md:grid-cols-2 md:gap-6">
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="occupation">
                  Occupation:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="text"
                  name="occupation"
                  id="occupation"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  value={editValues.occupation}
                  onChange={(e) =>
                    setEditValues({
                      ...editValues,
                      occupation: e.currentTarget.value,
                    })
                  }
                  placeholder="Enter occupation"
                  required
                />
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <label htmlFor="region">
                  Region:
                  <span className="text-red-700 text-[15px]"> *</span>
                </label>
                <input
                  type="text"
                  name="region"
                  id="region"
                  className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                  value={editValues.region}
                  onChange={(e) =>
                    setEditValues({
                      ...editValues,
                      region: e.currentTarget.value,
                    })
                  }
                  placeholder="Enter region"
                  required
                />
              </div>
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="matric">
                Matric:
                <span className="text-red-700 text-[15px]"> *</span>
              </label>
              <input
                type="text"
                name="matric"
                id="matric"
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                value={editValues.matric}
                onChange={(e) =>
                  setEditValues({
                    ...editValues,
                    matric: e.currentTarget.value,
                  })
                }
                placeholder="Enter years of experience"
                required
              />
            </div>

            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="levelOfEducation" className="tracking-wide">
                Highest Level Of Edcuation / Year:
                <span className="text-red-700 text-[15px]"> *</span>
              </label>
              <input
                type="text"
                name="levelOfEducation"
                id="levelOfEducation"
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                value={editValues.levelOfEducation}
                onChange={(e) =>
                  setEditValues({
                    ...editValues,
                    levelOfEducation: e.currentTarget.value,
                  })
                }
                placeholder="Enter level of edcuation"
                required
              />
            </div>
            <div className="relative z-0 w-full mb-5 group">
              <label htmlFor="file">CV</label>
              <input
                type="file"
                name="files"
                onChange={handleFileChange}
                className="w-full text-gray-800 rounded-md border border-blue-100 bg-white py-3 px-6 text-base font-medium outline-none"
                id="file"
                ref={fileInputRef}
                multiple
              />

              {/* Optional: Display selected file names */}
              {files &&
                files.map((file, index) => (
                  <div key={index} className="mt-2 text-gray-700">
                    {file.data.name}
                  </div>
                ))}
            </div>
            <button
              type="submit"
              disabled={isLoading}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-9 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {isLoading ? "Loading..." : "Save Edit"}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default ZTTEditForm;
