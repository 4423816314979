import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "./hooks/useAuth";
import React from "react";
import useToggle from "./hooks/useToggle";

const PersistLogin = () => {
  const location = useLocation();
  const { auth, setAuth } = useAuth();
  const [persist] = useToggle("persist", false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const key = JSON.parse(localStorage.getItem("token"));
    if (persist) {
      if (key) {
        setAuth({ ...auth, accessToken: key });
      } else {
        setAuth({ ...auth, accessToken: "" });
      }
    }
    setIsLoading(false);
  }, [setAuth]);

  if (isLoading) {
    return <div>Loading...</div>; // Optional loading state
  }

  return (
    <>
      {persist ? (
        <Outlet />
      ) : auth?.accessToken ? (
        <Outlet />
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
};

export default PersistLogin;
