import { useEffect } from "react";
import { RxCrossCircled } from "react-icons/rx";

const ApplicantDetail = ({ isOpen, onClose, listData, GoogleDrivelinks }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <>
      <div
        className={`fixed top-0 left-0 w-full h-full flex justify-end bg-[#717D8C] z-[1000] bg-opacity-25 ${
          isOpen ? "" : "hidden"
        }`}
      >
        <div className="fixed top-0 right-0 w-[300px] h-full md:w-[500px] sidebar_animation bg-[#F9FBFD] p-5 rounded overflow-scroll">
          <div className="flex justify-between pb-3 border-b">
            <h3 className="font-semibold text-lg">Applicant Detail</h3>
            <button
              onClick={onClose}
              className="font-semibold text-gray-400 text-[32px]"
            >
              <RxCrossCircled />
            </button>
          </div>
          <form className="mt-5 text-sm">
            {listData.map((item, index) => (
              <div key={index} className="w-full">
                <div className="w-full mb-3 z-0 group relative">
                  <h3 className="font-normal text-sm tracking-wide text-gray-500">
                    {item.name}
                  </h3>
                  <p
                    id={item.id}
                    className="w-full text-gray-500 text-base font-medium tracking-wide"
                  >
                    {item.value}
                  </p>
                </div>
                {item.dashed && (
                  <div className="border border-b border-dashed my-5"></div>
                )}
              </div>
            ))}
            {Array.isArray(GoogleDrivelinks) &&
              GoogleDrivelinks.map((value, index) => (
                <div
                  key={index}
                  className="w-full mb-5 z-0 group relative mt-3"
                >
                  <h3 className="font-normal text-sm tracking-wide text-gray-500">
                    Google Drive Link
                  </h3>
                  <p
                    id="last_name"
                    className="w-full text-gray-500 text-base font-medium break-words md:text-lg"
                  >
                    <a
                      href={`https://drive.google.com/file/d/${value}/view`}
                      className="text-blue-500 break-words"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <span>{`https://drive.google.com/file/d/${value}/view`}</span>
                    </a>
                  </p>
                </div>
              ))}
          </form>
        </div>
      </div>
    </>
  );
};

export default ApplicantDetail;
