import React from "react";
import { useRef, useState, useEffect, useContext } from "react";
import { Link, Navigate, useNavigate, useLocation } from "react-router-dom";
import AuthContext from "./context/AuthProvider";
import axios from "./api/axios";
import useInput from "./hooks/useInput";
import useToggle from "./hooks/useToggle";

const Login = () => {
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const from = location?.state?.from?.pathname || "/";
  const [persist, setToggle] = useToggle("persist", false);

  const [username, resetUsername, attrib] = useInput("username", "");
  const [email, resetEmail, attribute] = useInput("email", "");
  const [pwd, setPwd] = useState("");

  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    setErrMsg("");
  }, [email, pwd]);

  const LOGIN_URL = "/auth";
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        LOGIN_URL,
        JSON.stringify({ email, pwd }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      navigate(from, { replace: true });
      let accessToken = response?.data?.accessToken;
      if (persist) {
        localStorage.setItem(
          "token",
          JSON.stringify(response?.data?.accessToken)
        );
    } else {
          localStorage.setItem("token", JSON.stringify(""));
      }
      setAuth({
        email: email,
        password: pwd,
        accessToken,
      });
      //clear state and controlled inputs
      //need value attrib on inputs for this
      resetUsername("");
      resetEmail("");
      setPwd("");
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else {
        setErrMsg("Unauthorized");
      }
    }
  };
    // Redirect if already authenticated
    if (JSON.parse(localStorage.getItem("token"))) {
      return <Navigate to={from} state={{ from: location }} replace />;
    }
  return ( 
  <form action="/" onSubmit={handleSubmit}>
      <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center ">
        <div className="model-container">
          <div className="bg-indigo-700 text-center p-5 lg:w-[500px] h-[65vh] rounded-xl shadow-md">
            <p className={errMsg ? "errmsg" : "offscreen"}> {errMsg} </p>
            <h2 className="text-xl font-semibold  mt-6 mb-5 uppercase bg-indigo-700 text-white">
              Please Login Here
            </h2>
            <div className="mb-3  bg-transparent">
              <input
                type="text"
                name="name"
                id="name"
                placeholder="Username"
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6a64f1] focus:shodow-md"
                {...attrib}
                autoComplete="off"
                required
              />
            </div>
            <div className="mb-3  bg-transparent">
              <input
                type="email"
                name="email"
                id="email"
                placeholder="example@mail.com"
                className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6a64f1] focus:shodow-md"
                {...attribute}
                autoComplete="off"
                required
              />
            </div>
            <div className="mb-5 bg-indigo-700">
              <input
                type="password"
                name="password"
                id="password"
                placeholder="Enter your password"
                className="w-full rounded-md border  border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6a64f1] focus:shodow-md"
                value={pwd}
                onChange={(e) => setPwd(e.currentTarget.value)}
                required
              />
            </div>
            <div className=" bg-indigo-700">
              <button className="hover:shadow-md rounded-md bg-[#aaa6f3] hover:bg-orange-600 py-3 px-8 text-base font-semibold text-white outline-none">
                Login
              </button>
            </div>
            <button type="button" className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-semibold py-2 px-8 rounded inline-flex items-center mt-5">
              <input
                type="checkbox"
                id="persist"
                onChange={setToggle}
                checked={persist}
              />
              <label htmlFor="persist" className="pl-2 bg-inherit">
                Trust This Device
              </label>
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default Login;
