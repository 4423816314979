import { Tabs, TabsContent, TabsList, TabsTrigger } from "./shadcn/tabs";
import Applicants from "./tabs/applicants/Applicants";
import Dashboard from "./tabs/dashboard/Dashboard";
import Clients from "./tabs/clients/Clients";

const TabsSection = () => {
  return (
    <>
      <div className="px-3 md:px-5 pt-5">
        <Tabs defaultValue="dashboard">
          <TabsList className="flex justify-start font-semibold mb-5">
            <TabsTrigger value="dashboard" className="text-zinc-600 hover:text-zinc-400 tracking-wide">
              Dashboard
            </TabsTrigger>
            <TabsTrigger value="Applicants" className="text-zinc-600 hover:text-zinc-400 tracking-wide">
              Applicants
            </TabsTrigger>
            <TabsTrigger value="company" className="text-zinc-600 hover:text-zinc-400 tracking-wide">
              Clients
            </TabsTrigger>
          </TabsList>
          <TabsContent value="dashboard">
            <Dashboard />
          </TabsContent>
          <TabsContent value="Applicants">
            <Applicants />
          </TabsContent>
          <TabsContent value="company">
            <Clients />
          </TabsContent>
        </Tabs>
      </div>
    </>
  );
};

export default TabsSection;
