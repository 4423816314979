import { IoSearchOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import GridView from "./GridView";
import ColumnView from "./ColumnView";
import GridColumnView from "./GridColumnView";
import SelectDrop from "../../../SelectDrop";

const TableDemo = ({ tableHeader, tableScheme, tableData, status }) => {
  const swap = useSelector((state) => state.gridColumnReducer);

  return (
    <>
      <div className="w-full overflow-x-scroll md:overflow-visible mb-10">
        <div className="bg-white shadow-lg shadow-gray-100 border p-7 rounded-lg w-[1000px] md:w-full">
          <div className="flex bg-white pb-3 border-b px-3 mb-5">
            <label className="flex bg-inherit w-[60%]">
              <span className="text-[22px] flex justify-center items-center bg-inherit">
                <IoSearchOutline />
              </span>
              <input
                type="text"
                className="ml-3 text-gray-700 text-[15px] tracking-wide font-serif w-full h-full bg-inherit outline-none"
              />
            </label>
            <div className="flex gap-4 items-center justify-end w-[40%] bg-white">
              <div className="select">
                <SelectDrop />
              </div>
              <GridColumnView />
            </div>
          </div>
          {swap ? (
            <ColumnView
              tableHeader={tableHeader}
              tableScheme={tableScheme}
              tableData={tableData}
              status={status}
            />
          ) : (
            <GridView />
          )}
        </div>
      </div>
    </>
  );
};

export default TableDemo;
