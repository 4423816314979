import addApplicantAction from "../redux/Actions/addApplicantAction";
import isLoadingAction from "../redux/Actions/isLoadingAction";
import { useDispatch, useSelector } from "react-redux";
import { GiSettingsKnobs } from "react-icons/gi";
import { useEffect, useState } from "react";
import axios from "axios";

const SelectDrop = () => {
  const [selectToggle, setSelectToggle] = useState(false);
  const userId = useSelector((state) => state.filterReducer);
  const isLoading = useSelector((state) => state.isLoadingReducer);
  const dispatch = useDispatch();
  const [search, setSearch] = useState({
    firstName: "",
    email: "",
    occupation: "",
    cnic: "",
  });

  const handleClick = () => {
    setSelectToggle((prev) => !prev);
  };

  useEffect(() => {
    if (
      search.firstName === "" &&
      search.email === "" &&
      search.occupation === "" &&
      search.cnic === ""
    ) {
      request();
    }
  }, [search]);

  useEffect(() => {
    setSearch({
      firstName: "",
      email: "",
      occupation: "",
      cnic: "",
    });
  }, [userId]);

  const handleQuery = () => {
    request();
  };

  const request = async () => {
    const baseURL = `https://zamdainternational-crm-api.vercel.app/api/users/${userId}/applicants`;

    const queryParams = {
      firstName: "",
      email: "",
      occupation: "",
      cnic: "",
    };

    const params = `firstName=${search.firstName}&email=${search.email}&occupation=${search.occupation}&cnic=${search.cnic}`;

    const GET_USER = `${baseURL}?${params}`;

    try {
      dispatch(isLoadingAction(true));
      const response = await axios.get(GET_USER);
      dispatch(addApplicantAction(response.data.reverse()));
    } catch (err) {
      console.error(err);
    } finally {
      dispatch(isLoadingAction(false));
    }
  };
  return (
    <>
      <div className="container-fluid relative cursor-pointer z-50">
        <div
          className={`flex items-center justify-center px-5 py-[5px] text-gray-500 border border-blue-100 rounded-md ${search.firstName || search.email || search.occupation || search.cnic
              ? "blink"
              : "noblink"
            }`}
          onClick={handleClick}
        >
          <span className="inline-block mr-2 bg-inherit">
            <GiSettingsKnobs className="bg-inherit" />
          </span>
          Filter
        </div>
        {selectToggle && (
          <div className="absolute top-10 right-0 px-5 py-5 shadow-lg shadow-gray-300 w-[450px] dropDownMenu">
            <ul className="w-full text-gray-500 font-medium text-[13px]">
              <li className="flex justify-between mb-3">
                <label htmlFor="firstName" className="tracking-wide pr-3">
                  Name
                </label>
                <input type="text" name="firstName" id="firstName"
                  className="border px-2 py-2 rounded-sm w-[260px] outline-1 focus:outline-blue-300 font-normal" placeholder="Search name" value={search.firstName}
                  onChange={(e) =>
                    setSearch({ ...search, firstName: e.currentTarget.value })
                  }
                />
              </li>
              <li className="flex justify-between mb-3">
                <label htmlFor="email" className="tracking-wide pr-3">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="border px-2 py-2 rounded-sm w-[260px] outline-1 focus:outline-blue-300 font-normal"
                  placeholder="Enter email"
                  value={search.email}
                  onChange={(e) =>
                    setSearch({ ...search, email: e.currentTarget.value })
                  }
                />
              </li>
              <li className="flex justify-between mb-3">
                <label htmlFor="occupation" className="tracking-wide pr-3">
                  Occupation
                </label>
                <input
                  type="text"
                  name="occupation"
                  id="occupation"
                  className="border px-2 py-2 rounded-sm w-[260px] outline-1 focus:outline-blue-300 font-normal"
                  placeholder="Enter occupation"
                  value={search.occupation}
                  onChange={(e) =>
                    setSearch({ ...search, occupation: e.currentTarget.value })
                  }
                />
              </li>
              <li className="flex justify-between mb-3">
                <label htmlFor="cnic" className="tracking-wide pr-3">
                  CNIC
                </label>
                <input
                  type="text"
                  name="cnic"
                  id="cnic"
                  className="border px-2 py-2 rounded-sm w-[260px] outline-1 focus:outline-blue-300 font-normal"
                  placeholder="Enter CNIC"
                  value={search.cnic}
                  onChange={(e) =>
                    setSearch({ ...search, cnic: e.currentTarget.value })
                  }
                />
              </li>
            </ul>
            <button
              type="button"
              onClick={handleQuery}
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-9 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              {isLoading ? "Loading" : "Search"}
            </button>
          </div>
        )}
      </div>
    </>
  );
};

export default SelectDrop;
