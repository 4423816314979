const initialState = true;
const gridColumnReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SWAPING":
      return action.value;
    default:
      return state;
  }
};

export default gridColumnReducer;
