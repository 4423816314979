import { combineReducers } from "redux";
import filterReducer from "./filterReducer";
import addCompanyReducer from "./addCompanyReducer";
import gridColumnReducer from "./gridColumnReducer";
import addApplicantReducer from "./addApplicantReducer";
import isLoadingReducer from "./isLoadingReducer";

const rootReducer = combineReducers({
  filterReducer,
  addCompanyReducer,
  gridColumnReducer,
  addApplicantReducer,
  isLoadingReducer,
});

export default rootReducer;
