const initialState = [];
const addApplicantReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADDAPPLICANT":
      return action.value;
    default:
      return state;
  }
};

export default addApplicantReducer;
