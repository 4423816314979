import axios from 'axios';

export default axios.create({
    baseURL: 'https://zamdainternational-crm-api.vercel.app'
});

export const axiosPrivate = axios.create({
    baseURL: 'https://zamdainternational-crm-api.vercel.app',
    headers: { "Content-Type": "application/json" },
    withCredentials: true
});