const initialState = "66fc1c3696ba6984b614bd4a";
const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case "FILTER":
      return action.value;
    default:
      return state;
  }
};

export default filterReducer;
