import useLocalStorage from "./useLocalStorage";

const useInput = (key, initialValue) => {
    const [value, setValue] = useLocalStorage(key, initialValue);
    const reset = () => {
        setValue(initialValue);
    }
    const attribute = {
        value,
        onChange: (e) => setValue(e.currentTarget.value)
    }
    return [value, reset, attribute];
}

export default useInput;