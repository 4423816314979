import { useState } from "react";
import { useSelector } from "react-redux";
import ApplicantDetial from "../../../ApplicantDetial";
import { Statusbar } from "./Statusbar";
function GridView({ tableData }) {
  const userId = useSelector((state) => state.filterReducer);
  const [isModelOpen, setIsModelOpen] = useState(false);

  const [value, setValue] = useState([
    {
      cnic: "",
      email: "",
      firstName: "",
      lastName: "",
      phoneNumber: "",
    },
  ]);

  const closeModel = () => {
    setIsModelOpen(false);
  };

  const showInfo = (e) => {
    setValue(tableData.filter((value) => value._id === e.currentTarget.id));
    setIsModelOpen(true);
  };

  const [data] = value;
  const GoogleDrivelinks = data.GoogleDrivelinks;
  let listData;
  if (userId === "66fc1c3696ba6984b614bd4a") {
    listData = [
      {
        name: "First Name",
        value: data.firstName,
        id: "firstName",
        dashed: false,
      },
      {
        name: "Last Name",
        value: data.lastName,
        id: "lastName",
        dashed: false,
      },
      { name: "Email", value: data.email, id: "email", dashed: false },
      {
        name: "Phone Number",
        value: data.phoneNumber,
        id: "phoneNumber",
        dashed: false,
      },
      { name: "Address", value: data.address, id: "address", dashed: false },
      { name: "CNIC", value: data.cnic, id: "cnic", dashed: true },
      {
        name: "Desired Country",
        value: data.desiredCountry,
        id: "desiredCountry",
        dashed: false,
      },
      {
        name: "Date of Birth",
        value: data.dateOfBirth,
        id: "dateOfBirth",
        dashed: false,
      },
      { name: "Passport", value: data.passport, id: "passport", dashed: false },
      {
        name: "Occupation",
        value: data.occupation,
        id: "occupation",
        dashed: false,
      },
      { name: "Region", value: data.region, id: "region", dashed: true },
      {
        name: "Years of Experience",
        value: data.yearsOfExperience,
        id: "yearsOfExperience",
        dashed: false,
      },
      {
        name: "Highest Level Of Education / Year",
        value: data.levelOfEducation,
        id: "levelOfEdcuation",
        dashed: true,
      },
    ];
  } else if (userId === "66fc1c8d96ba6984b614bd4c") {
    listData = [
      {
        name: "First Name",
        value: data.firstName,
        id: "firstName",
        dashed: false,
      },
      {
        name: "Last Name",
        value: data.lastName,
        id: "lastName",
        dashed: false,
      },
      { name: "Email", value: data.email, id: "email", dashed: false },
      {
        name: "Phone Number",
        value: data.phoneNumber,
        id: "phoneNumber",
        dashed: false,
      },
      { name: "Address", value: data.address, id: "address", dashed: false },
      { name: "CNIC", value: data.cnic, id: "cnic", dashed: true },
      {
        name: "Desired Country",
        value: data.desiredCountry,
        id: "desiredCountry",
        dashed: false,
      },
      {
        name: "IELTS/PTE Score",
        value: data.IELTS_PTE_Score,
        id: "IELTS_PTE_Score",
        dashed: false,
      },
      {
        name: "Date of Birth",
        value: data.dateOfBirth,
        id: "dateOfBirth",
        dashed: false,
      },
      { name: "Passport", value: data.passport, id: "passport", dashed: false },
      {
        name: "Occupation",
        value: data.occupation,
        id: "occupation",
        dashed: false,
      },
      { name: "Region", value: data.region, id: "region", dashed: true },
      { name: "matric", value: data.matric, id: "matric", dashed: false },
      {
        name: "Highest Level Of Education / Year",
        value: data.levelOfEducation,
        id: "levelOfEdcuation",
        dashed: true,
      },
    ];
  } else if (userId === "66fc1cad96ba6984b614bd4e") {
    listData = [
      {
        name: "First Name",
        value: data.firstName,
        id: "firstName",
        dashed: false,
      },
      {
        name: "Last Name",
        value: data.lastName,
        id: "lastName",
        dashed: false,
      },
      { name: "Email", value: data.email, id: "email", dashed: false },
      {
        name: "Phone Number",
        value: data.phoneNumber,
        id: "phoneNumber",
        dashed: false,
      },
      { name: "Address", value: data.address, id: "address", dashed: false },
      { name: "CNIC", value: data.cnic, id: "cnic", dashed: true },
      {
        name: "Desired Country",
        value: data.desiredCountry,
        id: "desiredCountry",
        dashed: false,
      },
      {
        name: "IELTS/PTE Score",
        value: data.IELTS_PTE_Score,
        id: "IELTS_PTE_Score",
        dashed: false,
      },
      {
        name: "Date of Birth",
        value: data.dateOfBirth,
        id: "dateOfBirth",
        dashed: false,
      },
      { name: "Passport", value: data.passport, id: "passport", dashed: false },
      {
        name: "Occupation",
        value: data.occupation,
        id: "occupation",
        dashed: false,
      },
      { name: "Region", value: data.region, id: "region", dashed: true },
      { name: "matric", value: data.matric, id: "matric", dashed: false },
      {
        name: "Highest Level Of Education / Year",
        value: data.levelOfEducation,
        id: "levelOfEdcuation",
        dashed: true,
      },
    ];
  }
  return (
    <>
      <ApplicantDetial isOpen={isModelOpen} onClose={closeModel} listData={listData} GoogleDrivelinks={GoogleDrivelinks} />
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 bg-white">
        {tableData.map((value, index) => (
          <div key={index}>
            <div className="pl-6 pt-6 pb-6 pr-6 lg:pl-8 lg:pt-8 lg:pb-8 lg:pr-0 bg-white cursor-pointer rounded-lg shadow-lg shadow-gray-50 border border-gray-200">
              <div className="font-medium bg-white text-gray-700">
                <div className="items-top flex space-x-2 bg-white">
                  <div className="grid gap-1.5 leading-none bg-inherit">
                    <label htmlFor="terms1" className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 bg-inherit" >
                      <span className="text-[16px] tracking-wider font-[Poppins] text-gray-500 bg-inherit cursor-pointer" id={value._id} onClick={showInfo}>
                        {value.firstName}
                      </span>
                    </label>
                    <p className="text-[12px] text-gray-400 font-[Poppins] bg-inherit mb-2" id={value._id} onClick={showInfo}>
                      {value.email}
                    </p>
                    <p className="text-[14px] text-gray-600 tracking-wide bg-inherit">
                      <Statusbar emp_id={value._id} value={value} />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default GridView;
